import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "404-Error-Page-not-Found-with-people.png" }) {
        base
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="container page-wrapper page-404-content">
        <Img fluid={data.file.childImageSharp.fluid} alt={data.file.base} />
      </div>
    </Layout>
  )
}

export default NotFoundPage
